<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="18">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>区域统计</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="6" class="add-btn-container">
          <el-tag>总数量：{{ totalnum }}</el-tag>
          <el-tag>总金额：{{ totalprice }}</el-tag>
        </el-col>
      </el-row>
    </div>

    <el-container>

      <el-main>
        <!-- 列表内容 -->
        <div class="main">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="月份">
              <el-date-picker v-model="smonth" type="month" format="yyyy-MM" value-format="yyyy-MM" placeholder="选择月">
              </el-date-picker>
            </el-form-item>


            <el-form-item>
              <el-button type="primary" @click="onCurrentChange(1)">查询</el-button>
              <el-button type="primary" @click="rexport()">导出</el-button>

            </el-form-item>
          </el-form>
          <el-table :data="adminList" border="" style="width: 100%">
            <el-table-column prop="smonth" label="日期" align="center" width="230"></el-table-column>

            <el-table-column prop="collectcity" label="城市" align="center"></el-table-column>
            <el-table-column prop="collectarea" label="区域" align="center"></el-table-column>

            <el-table-column prop="productnum" label="数量" align="center"></el-table-column>
            <el-table-column prop="tprice" label="销售金额" align="center"></el-table-column>

          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination background="" layout="total,prev, pager, next" :page-size="10" :current-page="pageIndex"
            :total="pageTotal" @current-change="onCurrentChange" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getproductarealist, getproductarealistexprot } from '@/api/fj_order'
import { getPCTreeList } from '@/api/home'

import { getSelectRequest } from "@/api/zrproducttype";
export default {
  name: 'advlist',

  data() {
    return {
      id: 0,
      productname: '',
      price: '',
      smonth: '',
      giftenddate: [],
      giftenddatestr: '',
      paytime: [],
      paytimestr: '',
      shopname: '',
      iType: -1,
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
      orderby: 0,
      dfrow: {},
      typelist: [],
      arealist: [],
      producttypeid: '',
      totalnum: 0,
      totalprice: 0
    }
  },

  created() {
    this.gettypelist()
    this.getPCTreeList()
    this.getList()
  },
  methods: {
    getList() {
      getproductarealist({
        smonth: this.smonth,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then((res) => {
        console.log(res);

        this.totalprice = res.data.totalprice
        this.totalnum = res.data.totalnum
        let data = res.data.datalist
        this.adminList = data.data
        this.pageTotal = res.data.datalist.count
      })
    }, rexport() {
      //导出
      getproductarealistexprot({
        smonth: this.smonth,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then(res => {
        let blob = new Blob([res], {
          type: 'application/octet-stream'
        }) // 转化为blob对象
        console.log(blob)
        let filename = '区域统计.xlsx' // 判断是否使用默认文件名
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          var blobURL = window.URL.createObjectURL(blob) // 将blob对象转为一个URL
          var tempLink = document.createElement('a') // 创建一个a标签
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', filename) // 给a标签添加下载属性
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }
          document.body.appendChild(tempLink) // 将a标签添加到body当中
          tempLink.click() // 启动下载
          document.body.removeChild(tempLink) // 下载完毕删除a标签
          window.URL.revokeObjectURL(blobURL)
        }
      })
    },
    changeregion(e) {
      this.shipaddress = e.join(',')
      console.info(this.shipaddress)
    },

    getPCTreeList() {
      getPCTreeList().then(res => {
        console.log(res);
        this.arealist = res.data;
      });
    },
    gettypelist() {
      getSelectRequest().then(res => {
        console.log(res);
        this.typelist = res.data;
      });
    },
    paytimechange(e) {
      console.info(e)
      this.paytimestr = e.join(',')
    },
    onorderby(e) {
      console.info(e)
      this.orderby = e
      this.getList()
    },

    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
  },
}
</script> 

<style lang="less" scoped>

</style>